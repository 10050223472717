import logo from './Kaiju_Logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="logo" alt="logo" />

        <a
          className="downloadLink"
          href="https://drive.google.com/file/d/1tFFRkvKoEC6IVWqQiiPMwBYqT_ONMZ29/view"
          target="_blank"
          rel="noopener noreferrer"
          download
        >
        Book 2023
        </a>

      </header>
        <div style={{margin:'80px auto'}}>
            <iframe title="kaiju-animation-reel" src="https://player.vimeo.com/video/878393747?h=9920712f2b" width="640" height="360" frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
        </div>
    </div>
  );
}

export default App;
